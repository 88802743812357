/**
 * @file graphql/operator.ts
 * queries/mutations for Operator.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_OPERATORS_QUERY = gql`
  query Operators {
    operators {
      id
      name
    }
  }
`;

export const LOAD_OPERATOR_QUERY = gql`
  query Operator($id: ID!) {
    node(id: $id) {
      id
      ... on Operator {
        id
        name
        nameSlug
        isMoovsLiteOperator
        createdAt
        gettingStarted {
          id
          goal
          bannerRemovedAt
          taskFirstVehicleCompletedAt
          taskFirstDriverCompletedAt
          taskFirstQuoteCompletedAt
          taskFirstReservationCompletedAt
          taskMoovsPaymentAccountCompletedAt
          tasksRemaining
        }
        dudaWebsite {
          id
          createdAt
          desktopThumbnailUrl
          previewSiteUrl
          publishStatus
          siteDomain
          siteName
          templateId
          websitePlan
          websitePermissions
        }
        generalEmail
        voicePhoneNumber
        voicePhoneNumberInternational
        address
        websiteUrl
        permitNumber
        bookingEmail
        textPhoneNumber
        widgetBannerUrl
        nameSlug
        location
        companyLogoUrl
        automatedEmailsEnabled
        inboundEmailNotificationsEnabled
        inboundTextNotificationsEnabled
        driverStatusUpdateTextsEnabled
        driverAppAmountDueEnabled
        tripReminderTextsEnabled
        stripeAccountId
        gratuityWhenBooking
        customGratuityWhenBooking
        cashGratuityWhenBooking
        moovsChatNumberAsPrimary
        requireConsentWhenBooking
        enableCreditCardWhenBooking
        tripCancellationEmailEnabled
        driverReminderPendingJobsTextEnabled
        driverReminderUpdateTripStatusTextEnabled
        enabledOrderTypes {
          id
          name
          slug
          category
        }
        settings {
          customerTripCancellationRequestEnabled
          shuttleLateTextEnabled
          reviewSiteTextIncludeLink
          driverDeclineJobTextEnabled
          driverDeclineJobEmailEnabled
          skipVehicleSelectionEnabled
          skipVehicleDefaultVehicleId
          autoPaymentPreference
          autoPaymentDeposit {
            amt
            percent
          }
          pricingLayout {
            driverGratuityAmt
            driverGratuityPercent
            promoDiscountAmt
            promoDiscountPercent
            taxAmt
            taxPercent
            tollsAmt
            meetGreetAmt
            otherName
            otherAmt
            otherPercent
            other2Name
            other2Amt
            other2Percent
            other3Name
            other3Amt
            other3Percent
            meetGreetAmt
            meetGreetAmtEnabled
          }
          colorTheme
          requireConsentWhenBooking
          customerChangeAutomationEnabled
          customerChangeAutomationTimeType
          customerChangeAutomationTimeValue
          memberChangedStatusTextEnabled
          pobStatusTextToBcEnabled
          tripReminderTextTo
          tripStatusUpdateTextTo
          billingDetailsNotRequired
          promoCodes {
            id
            promoCodeName
            active
            redemptionCount
            promoCodeAmt
            promoCodePercent
            totalLimit
            bookingContactLimit
            bookingStartDate
            bookingEndDate
            tripStartDate
            tripEndDate
          }
          hasActivePromoCodes
          notifyDriverTripChangeEnabled
        }
        plan
        twilioPhoneNumber
        driverReminderTripReminderTextsEnabled
        customDomain
        customDomainFromName
        customDomainSendgridData
        customDomainIsValid
        moovsNetworkEnabled
        users {
          id
          firstName
          lastName
          mobilePhone
          email
          operatorId
          emailNormalize
          role {
            name
            slug
          }
          invitePending
        }
        bookingCutoffTimeValue
        bookingCutoffTimeType
        gnetId
        googleTrackingId
        enabledTripTypes {
          tripType
        }
        enabledTripCategories
        moovsMarketApps {
          id
          driverApp {
            id
            chargeAt
            active
          }
          salesAutomation {
            id
            chargeAt
            active
          }
          dynamicPricing {
            id
            chargeAt
            active
          }
          reviewPlatform {
            id
            chargeAt
            active
          }
          googleCalendar {
            id
            chargeAt
            active
          }
        }
        driverGratuityPresets
        settings {
          id
          luggageEnabled
          dynamicPricingRulesCombineEnabled
          stopPrice
          driverFlightUpdatedTextsEnabled
          internalFlightUpdatedTextsEnabled
          requireConsentWhenBooking
          requireSignatureWhenBooking
          reviewSite
          reviewSiteUrlPrefix
          reviewSiteUrlSuffix
          reviewSiteId
          widgetCta
        }
        lagoPlanCode
        stripeAccount {
          id
          isTreasuryEnabled
        }
      }
    }
  }
`;

export const UPDATE_OPERATOR_MUTATION = gql`
  mutation UpdateOperator($input: UpdateOperatorInput!) {
    updateOperator(input: $input) {
      operator {
        id
        name
        generalEmail
        voicePhoneNumber
        address
        websiteUrl
        permitNumber
        bookingEmail
        textPhoneNumber
        widgetBannerUrl
        companyLogoUrl
        nameSlug
        customDomainFromName
        driverGratuityPresets
        settings {
          pricingLayout {
            driverGratuityAmt
            driverGratuityPercent
            promoDiscountAmt
            promoDiscountPercent
            taxAmt
            taxPercent
            tollsAmt
            meetGreetAmt
            otherName
            otherAmt
            otherPercent
            other2Name
            other2Amt
            other2Percent
            other3Name
            other3Amt
            other3Percent
          }
        }
      }
    }
  }
`;

export const UPDATE_NAME_SLUG_MUTATION = gql`
  mutation UpdateNameSlug($input: UpdateNameSlugInput!) {
    updateNameSlug(input: $input) {
      operator {
        nameSlug
      }
    }
  }
`;

export const CREATE_SENDGRID_CUSTOM_DOMAIN = gql`
  mutation CreateSendgridCustomDomain(
    $input: CreateSendgridCustomDomainInput!
  ) {
    createSendgridCustomDomain(input: $input) {
      operator {
        id
        customDomain
        customDomainFromName
        customDomainSendgridData
        customDomainIsValid
      }
    }
  }
`;

export const REMOVE_SENDGRID_CUSTOM_DOMAIN = gql`
  mutation RemoveSendgridCustomDomain(
    $input: RemoveSendgridCustomDomainInput!
  ) {
    removeSendgridCustomDomain(input: $input) {
      operator {
        id
        customDomain
        customDomainFromName
        customDomainSendgridData
        customDomainIsValid
      }
    }
  }
`;

export const VALIDATE_SENDGRID_CUSTOM_DOMAIN = gql`
  mutation ValidateSendgridCustomDomain(
    $input: ValidateSendgridCustomDomainInput!
  ) {
    validateSendgridCustomDomain(input: $input) {
      operator {
        id
        customDomain
        customDomainFromName
        customDomainSendgridData
        customDomainIsValid
      }
    }
  }
`;

export const ENABLE_TEXT_NOTIFICATIONS_FOR_INBOUND_TEXTS = gql`
  mutation EnableInboundTextNotifications(
    $input: EnableInboundTextNotificationsInput!
  ) {
    enableInboundTextNotifications(input: $input) {
      operator {
        id
        inboundTextNotificationsEnabled
      }
    }
  }
`;

export const DISABLE_TEXT_NOTIFICATIONS_FOR_INBOUND_TEXTS = gql`
  mutation DisableInboundTextNotifications(
    $input: DisableInboundTextNotificationsInput!
  ) {
    disableInboundTextNotifications(input: $input) {
      operator {
        id
        inboundTextNotificationsEnabled
      }
    }
  }
`;

export const ENABLE_EMAIL_NOTIFICATIONS_FOR_INBOUND_TEXTS = gql`
  mutation EnableInboundEmailNotifications(
    $input: EnableInboundEmailNotificationsInput!
  ) {
    enableInboundEmailNotifications(input: $input) {
      operator {
        id
        inboundEmailNotificationsEnabled
      }
    }
  }
`;

export const DISABLE_EMAIL_NOTIFICATIONS_FOR_INBOUND_TEXTS = gql`
  mutation DisableInboundEmailNotifications(
    $input: DisableInboundEmailNotificationsInput!
  ) {
    disableInboundEmailNotifications(input: $input) {
      operator {
        id
        inboundEmailNotificationsEnabled
      }
    }
  }
`;

export const ENABLE_CREDIT_CARD_WHEN_BOOKING_MUTATION = gql`
  mutation enableCreditCardWhenBooking(
    $input: EnableCreditCardWhenBookingInput!
  ) {
    enableCreditCardWhenBooking(input: $input) {
      operator {
        id
        enableCreditCardWhenBooking
      }
    }
  }
`;

export const DISABLE_CREDIT_CARD_WHEN_BOOKING_MUTATION = gql`
  mutation disableCreditCardWhenBooking(
    $input: DisableCreditCardWhenBookingInput!
  ) {
    disableCreditCardWhenBooking(input: $input) {
      operator {
        id
        enableCreditCardWhenBooking
      }
    }
  }
`;

export const ENABLE_TEXT_FOR_DRIVER_STATUS_UPDATES = gql`
  mutation EnableDriverStatusTextNotifications(
    $input: EnableDriverStatusTextNotificationsInput!
  ) {
    enableDriverStatusTextNotifications(input: $input) {
      operator {
        id
        driverStatusUpdateTextsEnabled
      }
    }
  }
`;

export const DISABLE_TEXT_FOR_DRIVER_STATUS_UPDATES = gql`
  mutation DisableDriverStatusTextNotifications(
    $input: DisableDriverStatusTextNotificationsInput!
  ) {
    disableDriverStatusTextNotifications(input: $input) {
      operator {
        id
        driverStatusUpdateTextsEnabled
      }
    }
  }
`;

export const TOGGLE_DRIVER_APP_AMOUNT_DUE_DISPLAY = gql`
  mutation ToggleDriverAppAmountDueDisplay(
    $input: ToggleDriverAppAmountDueDisplayInput!
  ) {
    toggleDriverAppAmountDueDisplay(input: $input) {
      operator {
        id
        driverAppAmountDueEnabled
      }
    }
  }
`;

export const UPDATE_BOOKING_CUTOFF_TIME = gql`
  mutation UpdateBookingCutoffTime($input: UpdateBookingCutoffTimeInput!) {
    updateBookingCutoffTime(input: $input) {
      operator {
        id
        bookingCutoffTimeValue
        bookingCutoffTimeType
      }
    }
  }
`;

export const ENABLE_MOOVS_NETWORK = gql`
  mutation EnableMoovsNetwork($input: EnableMoovsNetworkInput!) {
    enableMoovsNetwork(input: $input) {
      operator {
        id
        moovsNetworkEnabled
      }
    }
  }
`;

export const ENABLE_TEXT_NOTIFICATIONS_FOR_TRIP_REMINDER = gql`
  mutation EnableTripReminderTextNotifications(
    $input: EnableTripReminderTextNotificationsInput!
  ) {
    enableTripReminderTextNotifications(input: $input) {
      operator {
        id
        tripReminderTextsEnabled
      }
    }
  }
`;

export const DISABLE_TEXT_NOTIFICATIONS_FOR_TRIP_REMINDER = gql`
  mutation DisableTripReminderTextNotifications(
    $input: DisableTripReminderTextNotificationsInput!
  ) {
    disableTripReminderTextNotifications(input: $input) {
      operator {
        id
        tripReminderTextsEnabled
      }
    }
  }
`;

export const UPDATE_OPERATOR_SETTINGS = gql`
  mutation UpdateOperatorSettings($input: UpdateOperatorSettingsInput!) {
    updateOperatorSettings(input: $input) {
      operator {
        id
        driverReminderPendingJobsTextEnabled
        driverReminderUpdateTripStatusTextEnabled
        tripCancellationEmailEnabled
        googleTrackingId
        driverGratuityPresets
        gratuityWhenBooking
        customGratuityWhenBooking
        cashGratuityWhenBooking
        moovsChatNumberAsPrimary
        settings {
          id
          customerTripCancellationRequestEnabled
          shuttleLateTextEnabled
          reviewSiteTextIncludeLink
          autoPaymentPreference
          autoPaymentDeposit {
            amt
            percent
          }
          luggageEnabled
          dynamicPricingRulesCombineEnabled
          stopPrice
          driverFlightUpdatedTextsEnabled
          internalFlightUpdatedTextsEnabled
          driverDeclineJobEmailEnabled
          driverDeclineJobTextEnabled
          skipVehicleSelectionEnabled
          skipVehicleDefaultVehicleId
          colorTheme
          widgetCta
          customerChangeAutomationEnabled
          customerChangeAutomationTimeType
          customerChangeAutomationTimeValue
          memberChangedStatusTextEnabled
          tripStatusUpdateTextTo
          pobStatusTextToBcEnabled
          tripReminderTextTo
          notifyDriverTripChangeEnabled
        }
      }
    }
  }
`;

export const UPDATE_OPERATOR_TRIP_TYPE_MUTATION = gql`
  mutation UpdateOperatorTripType($input: UpdateOperatorTripTypeInput!) {
    updateOperatorTripType(input: $input) {
      operator {
        id
        enabledTripTypes {
          tripType
        }
      }
    }
  }
`;

export const LOAD_DRIVER_PLATFORM_REPORT_QUERY = gql`
  query LoadDriverPlatformReport($startDate: Date!, $endDate: Date!) {
    loadDriverPlatformReport(startDate: $startDate, endDate: $endDate) {
      operatorTotalTrips
      drivers {
        driver {
          id
          firstName
          lastName
          driverProfilePhoto
        }
        driverTrips
      }
    }
  }
`;

export const UPDATE_OPERATOR_REVIEW_SITE_SETTINGS = gql`
  mutation UpdateOperatorReviewSiteSettings(
    $input: UpdateOperatorReviewSiteSettingsInput!
  ) {
    updateOperatorReviewSiteSettings(input: $input) {
      operator {
        id
        settings {
          id
          reviewSite
          reviewSiteUrlPrefix
          reviewSiteUrlSuffix
          reviewSiteId
        }
      }
    }
  }
`;

export const UPDATE_ONBOARDING_OPERATOR_MUTATION = gql`
  mutation UpdateOnboardingOperator($input: UpdateOnboardingOperatorInput!) {
    updateOnboardingOperator(input: $input) {
      operator {
        id
        onboardingCompleted
        location
        generalEmail
        name
        nameSlug
        address
        twilioPhoneNumber
        websiteUrl
        users {
          id
          firstName
          lastName
          email
          mobilePhone
        }
      }
    }
  }
`;

export const CREATE_OPERATOR_GETTING_STARTED_MUTATION = gql`
  mutation CreateOperatorGettingStarted(
    $input: CreateOperatorGettingStartedInput!
  ) {
    createOperatorGettingStarted(input: $input) {
      operator {
        id
        onboardingCompleted
        gettingStarted {
          id
          goal
          bannerRemovedAt
          taskFirstVehicleCompletedAt
          taskFirstDriverCompletedAt
          taskFirstQuoteCompletedAt
          taskFirstReservationCompletedAt
          taskMoovsPaymentAccountCompletedAt
          tasksRemaining
        }
      }
    }
  }
`;

export const UPDATE_OPERATOR_GETTING_STARTED_MUTATION = gql`
  mutation UpdateOperatorGettingStarted(
    $input: UpdateOperatorGettingStartedInput!
  ) {
    updateOperatorGettingStarted(input: $input) {
      operator {
        id
        onboardingCompleted
        gettingStarted {
          id
          goal
          bannerRemovedAt
          taskFirstVehicleCompletedAt
          taskFirstDriverCompletedAt
          taskFirstQuoteCompletedAt
          taskFirstReservationCompletedAt
          taskMoovsPaymentAccountCompletedAt
          tasksRemaining
        }
      }
    }
  }
`;
